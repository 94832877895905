div.portalstore_pagetitle_wrap.recampus {
    margin-left: 0;
    width: 880px;
}

.portalstore_pagetitle_wrap > h2 {
    font-size: 20px;
    font-weight: 100;
}

div.portal_redesign_container {
    /* Remove the width prior to moving into real code base */
    width: 570px;
    text-align: left;
    font-family: Arial, sans-serif;
    font-size: 12px;
}

div.portal_redesign_container.recampus {
    margin-left: 0;
    margin-top: 0px;
    width: 100%;
    margin-top: -10px;
}

div.portal_redesign_container h1 {
    font-family: Arial, sans-serif;
    font-variant: small-caps;
    font-weight: 300;
    color: #6e6e6e;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

div.portal_store_header {
    width: 570px;
    overflow: hidden;
    border-bottom: 1px solid #6e6e6e;
    margin: 20px 0px 5px 0px;
}

div.portal_store_header h1 {
    float: left;
}

div.portal_store_header a {
    text-decoration: none;
}

div.banner.recampus {
    width: 880px;
    border: 0px;
    border-bottom: 1px solid #cccccc;
}

div.recampus.ui-tabs-panel {
    background-color: #F7F7F7;
}

div.banner {
    width: 570px;
    height: 32px;
    background-color: #e0e0e0;
    margin-bottom: 0px;
    position: relative;
    border: 1px solid #cccccc;
}

div.banner ul {
    padding: 0px;
    list-style-type: none;
    display: inline;
    z-index: 1;
    width: 100%;
    margin: 0px 0px 0px 0px;
}

div.banner li {
    position: relative;
    display: block;
    float: left;
    height: 32px;
    text-align: left;
    font-size: 13px;
    z-index: 2;
    color: #333333 !important;
    line-height: 32px !important;
    text-align: center;
    padding: 0px 0px;
    margin: 0px 0px;
}

/* li classes for each step within store. They are percent based due to use in RE store and portal store */
li.step1 {
    width: 34.2%;
}

li.step2 {
    width: 33.3%;
}

li.step3 {
    width: 32.5%;
}

.step_inactive {
}

.step_active {
    font-weight: bold;
}

.float_left {
    float: left;
}

.border_right {
    border-right: 1px solid #929292;
}

.banner_navigation {
    bottom: 0px;
    left: 0px;
}

.banner_navigation a {
    position: relative;
    width: 100%;
    padding: 0px;
    line-height: 32px;
    display: inline-block; /* block allows the changing of line-height */
    text-decoration: none;
    text-align: center;
    color: black;
    z-index: 3;

}

.banner_navigation img {
    border: none;
    z-index: 0;
    position: absolute;
    top: 0px;
    left: 0px;
    border: 0px;
    height: 32px;
    width: 100%;
}

.banner_navigation a:visited {
    text-decoration: none;
    color: black;
}

.banner_navigation a:hover {
    text-decoration: none;
    color: black;
}

/* ************************ */

div.banner_navigation_bannerless {
    background-image: url(../images/portal_redesign/step_final.png);
    margin-bottom: 20px;
    position: relative;
    width: 570px;
    height: 32px;
    border: 1px solid # #a9aaaa;
}

div.banner_navigation_bannerless ul {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    display: inline;
}

div.banner_navigation_bannerless li {
    float: left;
    height: 32px;
    text-align: left;
    font-size: 14px;
    letter-spacing: 1px;
    color: white !important;
    background: url(../images/portal_redesign/step_inactive.png) right no-repeat;
    line-height: 32px;
    vertical-align: middle;
    padding: 0px 20px 0px 5px;
}

.banner_navigation_bannerless {
    background-image: url(../images/portal_redesign/step_final.png);
}

.banner_navigation_bannerless a {
    text-decoration: none;
    color: white !important;
}

.banner_navigation_bannerless img {
    border: none;
    position: relative;
    top: 5px;
    right: 2px;
    border: 0px;
}

.banner_navigation_bannerless a {
    text-decoration: none;
    color: white;
}

.banner_navigation_bannerless a:visited {
    text-decoration: none;
    color: white;
}

.banner_navigation_bannerless a:hover {
    text-decoration: none;
    color: white;
}

/* ************************ */

div.helper_text {
    text-align: left;
}

div.helper_text p {
    margin: 10px 0px 10px 0px;
    padding: 0px;
}

.rounded_boxes {
    width: 100%;
    text-align: left;
    background-color: #ffffff;
    border: 1px solid #b6b6b6;
    font-size: 12px;
    margin-bottom: 10px;
    /* Do rounding (native in Firefox and Safari) */
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
}

.rounded_boxes h1 {
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    padding: 10px 0px 3px 3px;
    margin: 0px;
    position: relative;
    top: -7px;
    /* background-image: url('/km/images/roundedheader.png'); */
    background-repeat: no-repeat;
    background-position: center bottom;
    border-bottom: 1px solid silver;
}

.product_boxes {
    width: 225px;
    margin: 10px;
    padding: 10px;
}

.product_boxes h3 {
    width: 100%;
    border-bottom: 1px solid #bbbbbb;
    color: #666666;
    margin: 0px;
    font-size: 13px;
}

.product_boxes p {
    font-size: 11px;
    color: black;
    margin: 10px 0px 0px 0px;
    padding: 0px;
}

.login_help_btn_img {
    border: medium none;
    height: 16px;
    margin: 0 3px -3px 0;
    padding: 0;
    width: 16px;
}

.login_help_btn_div {
    background-color: #DDDDDD;
    border: 1px solid #999999;
    color: #333333;
    cursor: pointer;
    display: block;
    float: left;
    font-family: Arial, Verdana, sans-serif;
    font-size: 90%;
    font-weight: bold;
    line-height: 130%;
    margin: auto;
    padding: 5px 3px;
    text-decoration: none;
}

.help_icon_cursor {
    float: right;
    cursor: pointer;
}

.help_icon_cursor.recampus {
    float: right;
    cursor: pointer;
    margin-right: 32px;
}

.help_icon_cursor_nofloat {
    cursor: pointer;
    vertical-align: middle;
}

.radio_button_midalign {
    vertical-align: middle;
    margin-bottom: 5px;
    *margin-bottom: 0px;
}

div.productLine div.buttons {
    margin: 5px;
}

div.productLine {
    width: 45%;
    margin-top: 10px;
    margin-bottom: 12px;
    padding-right: 0px;
    position: relative;
    min-height: 335px;
    height: auto !important;
    float: left;
}

div.productLine_wrapper {
    border-bottom: 1px solid #c7c7c7;
    border-top: 0px;
    padding: 0;
    margin: 0px 20px 0px 0px;
    width: 235px;
    float: left;
}

div.productLine img {
    width: 230px;
    border: 0;
}

div.productLine .siteLogo {
    width: 230px;
    margin: 40px auto;
}

div.productLine .siteLogo img {
    width: 136px;
    height: 80px;
    border: 0;
}

div.productLine .siteLogo img {
    width: 136px;
    height: 80px;
    border: 0;
}

div.odd {
    margin-right: 20px;
    border-right: 1px solid #aeaaaa;
}

div.productLine_separator {
    clear: both;
    float: left;
    height: 2px;
    width: 100%;
    border-bottom: 1px solid #aaa;
    position: relative;
    z-index: 6;
}

div.plTitle {
    padding: 4px;
    font-size: 16px;
    color: #383838;
    font-weight: bold;
    height: 30px;
}

.productLineForm {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.productLineListingContainer .plain_table td {
    padding: 12px 6px;
    vertical-align: top;
}

.productLineListingContainer .plain_table th {
    padding: 12px 6px;
    vertical-align: bottom;
}

.productLineListingContainer .plain_table {
    width: 100%;
}

.productLineImageDiv {
    text-align: center;
}

.productLineImage {
    display: inline-block;
}

.credit-cells > :first-child {
    float: left;
    width: 75%;
    min-height: 0px;
}

.credit-cells > :last-child {
    float: right;
    width: 22%;
    min-height: 0px;
}

.credit-cells {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    width: 100%;
}

.dropdown_button_wrap {
    margin-top: 25px;
    padding: 5px;
}

div.plDescription {
    margin-top: 4px;
    padding: 4px;
    height: 130px;
    overflow: auto;
}

.dropdowns-container {
    width: 65%;
    margin: auto;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
}

.odd .dropdowns-container {
    padding-right: 22px;
}

.dropdowns-container select {
    color: inherit !important;
    border-color: #CCC !important;
    box-shadow: none !important;
}

div.dropdown {
    height: 40px;
    margin-bottom: 8px;
    padding: 5px;
}

div.dropdownLabel, div.dropdownError {
    font-family: Arial, sans-serif;
    margin-bottom: 4px;
    font-size: 11px;
    line-height: 14px;
    height: 14px;
}

div.dropdownError {
    color: red;
}

div#tabs {
    float: left;
    width: 570px;
}

select.wide,
input.wide {
    width: 100%;
}

.login-wrapper input:not([type="submit"]) {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    height: 2em;
}

.login-wrapper .row-fluid {
    margin-bottom: 8px;
}

.login-wrapper form {
    margin: 0px;
}

.portal_tab_body {
    border: 1px solid black;
}

.tabs {
}

.example {
    margin: 0 1em;
    padding: 5px 0 0 0;
    margin: 5px 10px 20px 10px;
}

.ui-tabs-nav {
    line-height: 1.4 !important;
}

.ui-state-hover {
    background-repeat: repeat !important; /* fills in gap at the bottom */
}

.ui-tabs-nav:hover {
    line-height: 1.4 !important;
    background-repeat: repeat !important;
}

.ui-tabs-paging-next {
    float: right !important;
}

.ui-tabs-paging-prev,
.ui-tabs-paging-next {
    background: #fff !important;
    border-top: 0px solid #aeaaaa !important;
    border-left: 0px solid #aeaaaa !important;
    border-right: 0px solid #aeaaaa !important;
    margin-bottom: 1px !important;
    padding-bottom: 2px !important;
    padding: 0px !important;
    height: 30px;
}

#example2 .ui-tabs-paging-prev,
#example2 .ui-tabs-paging-next {
    font-weight: bold;
}

.ui-tabs-paging-prev a,
.ui-tabs-paging-next a,
.ui-tabs-paging-prev a:active,
.ui-tabs-paging-next a:active,
.ui-tabs-paging-next a:focus,
.ui-tabs-paging-prev a:focus {
    position: relative;
    top: 0px;
    padding: 0px 0px 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
    text-decoration: none !important;
    cursor: pointer;
}

.ui-tabs-paging-disabled {
    visibility: hidden;
}

div#prev_button {
    width: 24px;
    height: 29px;
    background: url('/images/button_icons/portal_arrows_sprite.gif') -2px -34px;
}

div#prev_button:hover {
    width: 24px;
    height: 29px;
    background: url('/images/button_icons/portal_arrows_sprite.gif') -2px -2px;
}

div#next_button {
    width: 24px;
    height: 29px;
    background: url('/images/button_icons/portal_arrows_sprite.gif') -24px -34px;
}

div#next_button:hover {
    width: 24px;
    height: 29px;
    background: url('/images/button_icons/portal_arrows_sprite.gif') -24px -2px;
}

/* Start: Address Selection Page */
div.studentName, div.currentAddress {
    float: left;
    height: 40px;
    line-height: 40px;
}

div.studentName {
    font-weight: bold;
    width: 200px;
}

div.studentName.disabled {
    color: #999;
}

div.currentAddress.disabled {
    color: #999;
}

table.address, div.shipCategory {
    margin-left: 60px;
}

table.address tr td {
    padding-bottom: 6px;
    vertical-align: top;
}

div.shipCategory {
    margin-bottom: 8px;
    font-weight: bold;
}

hr.sep {
    margin: 8px 20px;
    height: 1px;
    width: 280px;
    background-color: #999;
}

label.addressLabel {
    width: 130px;
    display: block;
    float: left;
}

input.addressRadio {
    display: block;
    float: left;
}

a.studentPreview {
    display: block;
    text-decoration: none;
}

div.addressData {
    display: none;
}

div.student {
}

div.addressFormError {
    color: red;
    width: 200px;
}

div.formError {
    color: red;
    font-weight: bold;
}

div.formSuccess {
    color: green;
    font-weight:bold;
}

/* End: Address Selection Page */

div.help_header_wrapper {
    float: left;
    clear: both;
    width: 100%;
}

div.help_header {
    float: left;
    width: 92%;
}

div.help_icon {
    float: right;
}

a.removeuser {
    display: block;
    float: left;
    margin-right: 8px;
}

div.username {
    float: left;
}

a.removeuser.disabled {
    color: #666;
    text-decoration: none;
    cursor: arrow;
}

div#user-list-popup {
    display: none;
}

div#userList {
    float: left;
    width: 570px;
    margin: 0px;
    border-left: 1px solid #aeaaaa;
    border-right: 1px solid #aeaaaa;
    border-bottom: 1px solid #aeaaaa;
    background-color: #eee;
    padding: 8px;
    display: none;
}

div.column {
    width: 251px;
    float: left;
    line-height: 18px;
}

div.column.first {
    margin-right: 8px;
}

a#viewAll {
    display: block;
    float: left;
    line-height: 24px;
    height: 24px;
}

div.admin_users_toolbar.recampus {
    width: 683px;
}

div.admin_users_toolbar {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background-repeat: repeat-x;
    border: 0px 0px 0px 0px;
    width: 570px;
    float: left;
    clear: both;
    font-size: 10px;
    padding-top: 6px;
}

.breadcrumb_item {
    min-height: 12px !important;
    padding: 0px;
    margin: 0px;
    font-weight: normal;
}

.breadcrumb_item a {
    color: #0088cc;
}

/* Product Selection page */
div.groupTabDisplay {
    width: 98%;
    *width: 96%;
}

div.productBox, div.productBox_odd {
    padding: 2px;
    margin: 10px 5px 13px 0px;
    width: 235px;
    float: left;
}

div.productBox_odd {
    margin-right: 19px !important;
    *margin-right: 24px !important;
}

div.productBox div.purchaseBox {
    padding: 0px 10px;
    margin: 0px;
}

div.productBox div.productContent div.purchaseBox p {
    text-align: center;
}

div.productBox div.productContent div.purchaseBox form {
    margin: 5px;
}

div.productBox div.purchaseBox span.buttons input.add {
    margin: auto;
}

div.productBox div.productHeader {
    width: 96%;
    *width: 100%;
    font-weight: bold;
    font-size: 13px;
    color: #1c1c1c;
    padding: 10px;
}

div.productBox div.productContent {
    width: 98%;
    padding: 0px;
    font-size: 12px;
}

div.productDescriptions {
    padding: 0px 10px 0px 10px;
}

div.productBox div.productContent p {
    margin-top: 5px;
    margin-bottom: 0px;
}

div.productBox div.productContent div.purchaseBox div.price_info_wrap {
    display: block;
}

div.productBox div.productContent div.purchaseBox div.price_info_wrap .price {
    font-size: 16px;
    color: #1e1e1e;
}

div.productBox div.productContent div.purchaseBox div.price_info_wrap .price .price_tag {
    font-weight: bold;
}

div.productBox div.productContent div.purchaseBox div.price_info_wrap div.moreInfo {
    float: left;
    margin: 7px 4px 4px 0px;
    padding-left: 10px;
    border-left: 1px solid #2d2d2d;
    font-size: 11px;
    font-weight: bold;
}

div.productBox div.productContent div.purchaseBox form.order_form {
    padding: 0px;
    text-align: center;
}

.productBox_right {
    position: absolute;
    top: 0px;
    right: 0px;
    margin: 2px;
}

.productBox.productBox_right_spacer {
    float: right;
    height: 1em;
}

.productBox.productBox_longListing {
    float: right;
    margin-top: 0px;
    margin-bottom: 0px;
}

.productBox.productBox_longListing div,
.productBox.productBox_longListing .order_form {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.productBox.productBox_longListing .price {
    display: none;
}

div.box_text h2 {
    border: 0px;
    color: #666666;
    font-weight: 300;
}

/* End Product Selection page */

/* User Select Dialog */
table.portal_userlist_wrapper {
    width: 100%;
}

table.portal_userlist_wrapper td.portal_userlist_add {
    width: 45%;
    border: 1px solid #CCCCCC;
    vertical-align: top;
    position: relative;
}

table.portal_userlist_wrapper td.portal_userlist_selected {
    width: 55%;
    border: 1px solid #CCCCCC;
    vertical-align: top;
}

table.portal_userlist_wrapper td.portal_userlist_add p.portal_userlist_selectAll {
    float: right;
}

div.portal_useradd_header1 {
    font-weight: bold;
    font-size: 11px;
    border: 1px solid #CCCCCC;
    padding: 3px;
    background: #d1d1d1 url(/images/portal_redesign/userlist_add_header.jpg) repeat-x;
}

div.portal_userselected_content {
    padding-top: 10px;
}

/* End User Select Dialog */

.portal_product_desc {
    margin-top: 1.5em;
    margin-bottom: 3em;
}

.portal_product_desc ul {
    list-style: disc;
    margin-top: 1em;
}

.portal_product_desc li {
    margin-bottom: 1em;
}

.portal_users_add_btn {
    cursor: pointer;
}

div.newUser_login_box {
    float: left;
    padding: 0px;
    width: 280px;
}

div.ss_header_text {
    float: left;
    width: 60%;
}

div.ss_header_image {
    float: left;
    width: 40%;
}

div.ss_header_image img {
    margin-top: 36px;
}

div.ss_header_extra {
    float: left;
}

div.ss_header_title {
    font-weight: bold;
    font-size: 1.1em;
    margin-bottom: 20px;
}

div.ss_header_description {
    margin-bottom: 20px;
    margin-right: 6px;
}

a.select_checkbox {
    background-color: #559548;
    padding: 6px 20px !important;
    line-height: 100% !important;
    color: #ffffff;
    font-weight: normal;
    text-transform: uppercase;
    display: inline-block;
    float: none;
    border: none;
    font-size: 12px;
    text-decoration: none;
    width: 90px;
    text-align: center;
    height: 14px;
}
a.select_checkbox .select_checkbox_text {
    color: #ffffff;
}
a#proceed_button{
    background-color: #559548;
    padding: 6px 20px !important;
    line-height: 100% !important;
    color: #ffffff;
    font-weight: normal;
    text-transform: uppercase;
    display: inline-block;
    border: none;
    font-size: 12px;
    text-decoration: none;
}
div.bundle_pricing_wrapper {
    float: left;
    padding-bottom: 14px;
}

div.cpa_bundle_pricing {
    height: 18px;
    line-height: 18px;
    text-align: center;
    float: left;
    width: 118px;
    margin-right: 10px;
    font-size: 0.8em;
    border: 1px solid #fff;
}

div.cpa_bundle_pricing.right {
    margin-right: 0;
}

div.ferpa_stripes {
    background: url(/images/stripe.png);
}

a.product-description-dialog-link:hover {
    color: #0088cc;
}

.portalstore_pagetitle_wrap h2 {
    line-height: initial;
    width: 600px;
    margin-top: 4px;
    float: left;
}

.shipping-header {
    font-size: 1.25em;
    font-weight: bold;
}

.shipping-names p {
    margin: 0px;
}

.shipping-grouping {
    margin-bottom: 12px;
}

.shipping-grouping input {
    margin: 0px;
    position: relative;
    top: -2px;
}

#shipping-policies {
    margin-top: 24px;
}

#shipping-policies ul {
    width: 90%;
}

#shipping-policies li,
#shipping-policies-popup li {
    line-height: 20px;
    margin-bottom: 8px;
}

.shipping-form select,
.shipping-form input {
    max-width: 100%;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    height: 2em;
}

.order-summary-header,
.shipping-header {
    background-color: #e2e2e2;
}

.order-summary-list [class*="span"] {
    position: relative;
    min-height: 1em;
    float: left;
    display: block;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.order-summary-list .row-fluid:before,
.order-summary-list .row-fluid:after {
    display: table;
    line-height: 0;
    content: "";
}

.order-summary-list .row-fluid:after {
    clear: both;
}

a.auto-progression-icon,
a.auto-progression-icon:active {
    position: absolute;
    left: -20px;
    color: red;
    font-size: 12px;
    font-weight: bold;
    font-family: serif;
    text-decoration: none;
}

.remove-X {
    font-size: 2em;
    text-decoration: none;
    display: block;
    width: .9em;
    max-height: 0px;
    line-height: 0.8em;
}

[class*="order-summary-products"],
.shipping-form,
.order-summary-details {
    border-bottom: 1px solid #d7d7d7;
}

.grouping [class*="order-summary-products"]:last-child,
.shipping-form,
.order-summary-details {
    border-bottom-width: 3px;
}

.single-product-price:after,
.order-summary-total:after {
    padding-right: 25px !important;
    content: "\200b";
}

.single-product-addon {
    padding-left: 1em;
}

.single-product-addon-sub {
    padding-left: 2em;
}

.single-product-quantity input[type="number"],
.single-product-quantity input[type="text"] {
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    height: 2em;
}

.single-product-quantity {
    width: 10%
}

.single-product-remove-X,
.single-product-price {
    width: 10%
}

.single-product-addon-sub,
.single-product-addon,
.single-product {
    width: 64%;
}

.single-product-addon-sub,
.single-product-addon,
.single-product,
.single-product-quantity,
.single-product-price {
    margin-left: 2%
}

.recipient-name,
.recipient-email,
.recipient-id {
    width: 28%;
    margin-left: 2%;
}

.order-summary-list [class*="single-product"],
.order-summary-list [class*="recipient-"],
.order-summary-details [class*="span"],
.order-summary-total,
.payment-summary [class*="span"],
.payment-total [class*="span"],
.shipping-header [class*="span"],
.shipping-form > [class*="span"] {
    padding-top: 8px;
    padding-bottom: 7px;
}

.order-summary-list [class*="single-product"]:first-child,
.order-summary-list [class*="recipient-"]:first-child,
.order-summary-details [class*="span"]:first-child,
.order-summary-total,
.payment-summary :not(.text-right)[class*="span"],
.payment-total :not(.text-right)[class*="span"],
.shipping-header [class*="span"]:first-child,
.shipping-form > [class*="span"]:first-child {
    padding-left: 16px;
}

.order-summary-list [class*="single-product"]:last-child,
.order-summary-list [class*="recipient-"]:last-child,
.order-summary-details [class*="span"]:last-child,
.order-summary-total,
.payment-summary .text-right[class*="span"],
.payment-total .text-right[class*="span"],
.shipping-header [class*="span"]:last-child,
.shipping-form > [class*="span"]:last-child {
    padding-right: 16px;
}

.order-summary-header {
    margin-bottom: 3px;
    cursor: pointer;
}

.order-summary-recipient > div {
    width: 100%;
}

.order-summary-product-name {
    font-weight: bold;
}

.recipient-remove-X {
    width: 8%;
    margin-left: 2%;
}

.qty-update-button {
    margin-top: 10px;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.text-green,
input.text-green {
    color: #559548;
}

#promoCodeForm {
    margin-top: 48px;
}

.payment-pays {
    margin-top: 24px;
    border-bottom: 1px solid #d7d7d7;
}

.payment-subtotal {
    border-top: 1px solid #d7d7d7;
}

.payment-total {
    border-top: 1px solid #d7d7d7;
    border-bottom: 1px solid #d7d7d7;
}

.payment-pays > :nth-child(odd), .payment-total > :first-child > :nth-child(odd) {
    font-weight: bold;
}

.payment-summary,
.payment-total {
    font-size: 14px;
}

.payment-summary > :not(:first-child) > :nth-child(odd), .payment-total > :not(:first-child) > :nth-child(odd) {
    padding-left: 30px;
}

.payment-wrapper #suggestionAndOverrides * {
    max-width: 100%;
}

.payment-wrapper input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]),
.payment-wrapper select {
    width: 100%;
    height: 30px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.payment-wrapper input[type="submit"] {
    width: 100%;
    margin-top: 4px;
}

.payment-wrapper textarea {
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.payment-wrapper .input-grouping > div {
    padding: 8px 0px;
}

.payment-wrapper hr,
hr.cart-seperator,
hr.seperator {
    margin-top: 0px;
    border: none;
    border-top: 3px solid #d7d7d7;
    margin-bottom: 28px;
}

.payment-wrapper .promo-code {
    padding-left: 16px;
}

.payment-processing-message {
    font-weight: bold;
}

.payment-place-order-buttons input {
    width: 150px !important;
}

.payment-view-summary {
    float: right;
    position: relative;
    top: -26px;
    margin-right: 50px;
    height: 0px;
}

.payment-wrapper .required {
    color: red;
    font-size: 16px;
}

.payment-wrapper .row-fluid .span6.payment-billing-address-section {
    width: 40%;
    margin-left: 6%;
}

.payment-wrapper .row-fluid .span6.payment-billing-address-section > div.row-fluid:first-child,
.payment-wrapper .payment-enter-info-header {
    font-size: 12px;
}

.payment-wrapper .row-fluid .span6.optInOut {
    margin-left: 7%;
    width: 41%;
}

.confirm-dialog-buttons {
    margin-top: 24px;
}

.content-aware-error:not(:empty) {
    margin: 3px;
    padding: 5px;
    border: 2px solid #c11212;
    background-color: #f89797;
    font-weight: bold;
}

.content-aware-error p {
    margin: 0;
    padding: 5px 0;
}

.js-template {
    display: none !important;
}

.address-edit-block input[type="button"] {
    width: auto;
}

.address-edit-block input,
.address-edit-block select {
    margin: 8px 0px;
}

img[height="40"] {
    height: 40px;
}

img[height] {
    height: attr(height px);
}

.flex {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    clear: both;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.flex-item {
    width: 100%;
    align-self: flex-end;
}

.payment-wrapper label {
    margin-top: 5px;
    display: inline-block;
    width: 100%;
    margin-left: 5px;
}

.payment-wrapper label:not([for]) {
    cursor: text;
}

.push-for-checkbox {
    padding-left: 20px;
    position: relative;
}

.push-for-checkbox input[type=checkbox] {
    position: absolute;
    left: 0px;
    margin: 0px;
}

label.same-as-shipping-address-label {
    margin-left: 0;
    margin-top: 15px;
}

.search .heading {
    background-color: #c8c8c8;
    font-size: 16px;
    padding: 5px 8px;
}

.search > div {
    margin-bottom: 8px;
}

.search .heading {
    font-weight: 100;
    font-size: 16px;
}

#student-search {
    background-color: #FFFFFF;
    border: 2px solid #E0E0E0;
    margin-bottom: 20px;
}

#student-search .sg-content {
    padding: 0 5px;
    margin: 0;
}

#student-search .sg-content div {
    margin-left: 7px;
}

#student-search .sg-content:nth-of-type(1) {
    margin-bottom: 0;
}

.create-group-button-wrapper {
    width: 285px;
    text-align: center;
}

.create-group-button-wrapper h2 {
    font-weight: 300;
    font-size: 17px;
    margin-bottom: 18px;
}

.group-editor > div {
    width: 45%;
    float: left;
}

.group-editor > .buttons {
    height: 440px;
    width: 10%;
}

.group-editor form {
    height: 440px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: 2px solid #E0E0E0;
}

.group-editor > .buttons {
    width: 10%;
}

.group-editor input[type=checkbox] {
    display: inline-block;
    float: none;
    margin: 0px 6px 2px 0px;
}

.group-editor-body {
    height: 90%;
    background: #FBFBFB;
    border-top: 2px solid #E0E0E0;
}

.group-editor-header {
    height: 10%;
}

.group-editor-body > :nth-child(odd) {
    background: #F7F7F7;
}

.group-editor-body > :nth-child(even),
.group-editor-header {
    background: white;
}

.group-editor-body > :last-child:not(:nth-child(12n)) {
    border-bottom: 2px solid #D7D7D7;
}

.group-editor-body > label {
    display: none;
    clear: both;
    margin: 0px;
    font-size: 12px;
    border-top: 2px solid #D7D7D7;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    height: 8.333%;
}

.group-editor-body > label:first-child {
    margin-top: -2px;
}

.group-editor-body > * > :first-child,
.group-editor-header > :first-child {
    padding-left: 10px;
}

.group-editor-body > * > :last-child,
.group-editor-header > :last-child {
    padding-right: 13px;
}

.group-editor-body > * > * {
    padding: 4px 0px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.group-editor-header > * {
    padding-top: 12px;
}

.group-editor-title {
    font-size: 16px;
    font-weight: 100;
    padding-left: 10px;
}

.group-editor .selected {
    background: #D7D7D7;
}

.group-editor-nav .selected {
    background: #FFFFFF;
}

.group-editor input.group-editor-move {
    padding: 0px 0px 15px !important;
    position: absolute;
    font-size: 7em;
    height: 45px;
    width: 35px;
    line-height: 0px !important;
    left: 32%;
    font-weight: 100;
}

.group-editor-pages span {
    margin-left: 25px;
    font-size: 18px;
}

.group-editor-nav {
    border: 2px solid #E0E0E0;
    background: #FFFFFF;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding-right: 10px;
    min-height: 30px;
    line-height: 30px;
}

.group-editor-nav input {
    font-size: 10px;
}

/*css borrowed from bootstrap*/

.bootstrap-wrapper .row-fluid {
    width: 100%;
    *zoom: 1;
}

.bootstrap-wrapper .row-fluid:before,
.bootstrap-wrapper .row-fluid:after {
    display: table;
    line-height: 0;
    content: "";
}

.bootstrap-wrapper .row-fluid:after {
    clear: both;
}

.bootstrap-wrapper .row-fluid [class*="span"] {
    display: block;
    float: left;
    width: 100%;
    min-height: 30px;
    margin-left: 2.127659574468085%;
    *margin-left: 2.074468085106383%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.bootstrap-wrapper .row-fluid [class*="span"]:first-child {
    margin-left: 0;
}

.bootstrap-wrapper .row-fluid .controls-row [class*="span"] + [class*="span"] {
    margin-left: 2.127659574468085%;
}

.bootstrap-wrapper .row-fluid .span12 {
    width: 100%;
    *width: 99.94680851063829%;
}

.bootstrap-wrapper .row-fluid .span11 {
    width: 91.48936170212765%;
    *width: 91.43617021276594%;
}

.bootstrap-wrapper .row-fluid .span10 {
    width: 82.97872340425532%;
    *width: 82.92553191489361%;
}

.bootstrap-wrapper .row-fluid .span9 {
    width: 74.46808510638297%;
    *width: 74.41489361702126%;
}

.bootstrap-wrapper .row-fluid .span8 {
    width: 65.95744680851064%;
    *width: 65.90425531914893%;
}

.bootstrap-wrapper .row-fluid .span7 {
    width: 57.44680851063829%;
    *width: 57.39361702127659%;
}

.bootstrap-wrapper .row-fluid .span6 {
    width: 48.93617021276595%;
    *width: 48.88297872340425%;
}

.bootstrap-wrapper .row-fluid .span5 {
    width: 40.42553191489362%;
    *width: 40.37234042553192%;
}

.bootstrap-wrapper .row-fluid .span4 {
    width: 31.914893617021278%;
    *width: 31.861702127659576%;
}

.bootstrap-wrapper .row-fluid .span3 {
    width: 23.404255319148934%;
    *width: 23.351063829787233%;
}

.bootstrap-wrapper .row-fluid .span2 {
    width: 14.893617021276595%;
    *width: 14.840425531914894%;
}

.bootstrap-wrapper .row-fluid .span1 {
    width: 6.382978723404255%;
    *width: 6.329787234042553%;
}

.bootstrap-wrapper .row-fluid .offset12 {
    margin-left: 104.25531914893617%;
    *margin-left: 104.14893617021275%;
}

.bootstrap-wrapper .row-fluid .offset12:first-child {
    margin-left: 102.12765957446808%;
    *margin-left: 102.02127659574467%;
}

.bootstrap-wrapper .row-fluid .offset11 {
    margin-left: 95.74468085106382%;
    *margin-left: 95.6382978723404%;
}

.bootstrap-wrapper .row-fluid .offset11:first-child {
    margin-left: 93.61702127659574%;
    *margin-left: 93.51063829787232%;
}

.bootstrap-wrapper .row-fluid .offset10 {
    margin-left: 87.23404255319149%;
    *margin-left: 87.12765957446807%;
}

.bootstrap-wrapper .row-fluid .offset10:first-child {
    margin-left: 85.1063829787234%;
    *margin-left: 84.99999999999999%;
}

.bootstrap-wrapper .row-fluid .offset9 {
    margin-left: 78.72340425531914%;
    *margin-left: 78.61702127659572%;
}

.bootstrap-wrapper .row-fluid .offset9:first-child {
    margin-left: 76.59574468085106%;
    *margin-left: 76.48936170212764%;
}

.bootstrap-wrapper .row-fluid .offset8 {
    margin-left: 70.2127659574468%;
    *margin-left: 70.10638297872339%;
}

.bootstrap-wrapper .row-fluid .offset8:first-child {
    margin-left: 68.08510638297872%;
    *margin-left: 67.9787234042553%;
}

.bootstrap-wrapper .row-fluid .offset7 {
    margin-left: 61.70212765957446%;
    *margin-left: 61.59574468085106%;
}

.bootstrap-wrapper .row-fluid .offset7:first-child {
    margin-left: 59.574468085106375%;
    *margin-left: 59.46808510638297%;
}

.bootstrap-wrapper .row-fluid .offset6 {
    margin-left: 53.191489361702125%;
    *margin-left: 53.085106382978715%;
}

.bootstrap-wrapper .row-fluid .offset6:first-child {
    margin-left: 51.063829787234035%;
    *margin-left: 50.95744680851063%;
}

.bootstrap-wrapper .row-fluid .offset5 {
    margin-left: 44.68085106382979%;
    *margin-left: 44.57446808510638%;
}

.bootstrap-wrapper .row-fluid .offset5:first-child {
    margin-left: 42.5531914893617%;
    *margin-left: 42.4468085106383%;
}

.bootstrap-wrapper .row-fluid .offset4 {
    margin-left: 36.170212765957444%;
    *margin-left: 36.06382978723405%;
}

.bootstrap-wrapper .row-fluid .offset4:first-child {
    margin-left: 34.04255319148936%;
    *margin-left: 33.93617021276596%;
}

.bootstrap-wrapper .row-fluid .offset3 {
    margin-left: 27.659574468085104%;
    *margin-left: 27.5531914893617%;
}

.bootstrap-wrapper .row-fluid .offset3:first-child {
    margin-left: 25.53191489361702%;
    *margin-left: 25.425531914893618%;
}

.bootstrap-wrapper .row-fluid .offset2 {
    margin-left: 19.148936170212764%;
    *margin-left: 19.04255319148936%;
}

.bootstrap-wrapper .row-fluid .offset2:first-child {
    margin-left: 17.02127659574468%;
    *margin-left: 16.914893617021278%;
}

.bootstrap-wrapper .row-fluid .offset1 {
    margin-left: 10.638297872340425%;
    *margin-left: 10.53191489361702%;
}

.bootstrap-wrapper .row-fluid .offset1:first-child {
    margin-left: 8.51063829787234%;
    *margin-left: 8.404255319148938%;
}

#creditCardDialog .orderTotal{
    font-weight: bold;
}

#creditCardDialog .orderTotal > div:first-child{
    padding-left: 15px;
}

#creditCardDialog .orderDeferred > div:first-child{
    padding-left: 35px;
}

#creditCardDialog .orderTotal > div:last-child, #creditCardDialog .orderDeferred > div:last-child{
    padding-right: 45px;
}
